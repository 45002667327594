import React, { FC } from 'react';
import Carousel from 'nuka-carousel/lib/carousel';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import { IInfluencerSliderComponentProps } from './models';
import './InfluencerSlider.scss';

const InfluencerSlider: FC<IInfluencerSliderComponentProps> = ({ influencers, openModal }) => {
  const { isMobile, isTablet } = useScreenRecognition();

  return (
    <Carousel
      className="influencers__slider"
      slidesToShow={isMobile ? 1 : isTablet ? 3 : 4}
      withoutControls
    >
      {influencers.map(({ properties: { title, subTitle, image, cta } }) => (
        <div
          aria-hidden="true"
          key={title}
          onClick={(e) => openModal(e, cta[0]?.properties.url[0]?.url, title)}
        >
          <GatsbyImage
            isLazyLoading
            wrapperClasses="article-card__image"
            fluid={image.gatsbyImage?.childImageSharp.fluid}
            alt={influencers[0]?.properties.image.altText}
          />
          <DangerouslySetInnerHtml
            element="h2"
            className="influencers__slider__title"
            html={title}
          />
          <h4 className="influencers__slider__subtitle">{subTitle}</h4>
        </div>
      ))}
    </Carousel>
  );
};

export default InfluencerSlider;

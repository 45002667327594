import Banner from 'components/Banner';
import RelatedArticles from 'components/RelatedArticles';
import RelatedProducts from 'components/RelatedProducts';
import { graphql } from 'gatsby';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { Layout } from 'layout';
import breakpoints from 'gatsby-theme-husky/src/components/RelatedArticles/constants';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';
import React, { FC, useState } from 'react';
import InfluencerSlider from 'components/InfluencerSlider';
import Modal from '../../components/Modal/Modal';
import { IInfluencerPageComponentProps } from './models';
import './InfluencerPage.scss';

const InfluencerPage: FC<IInfluencerPageComponentProps> = ({
  data: {
    umbracoInfluencer: {
      banner: [{ properties: banner }],
      influencers,
      defaultCompositions,
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      url,
      relatedProductsCardCtaButton,
      relatedProductsCtaButtonShow,
      relatedProductsTitle,
      relatedProductsCardCTAButtonAriaLabel,
      relatedProductsCtaButton,
      relatedArticlesTitle,
      relatedArticlesCTAButtonAriaLabel,
      relatedArticlesCTAButtonText,
      extraFooter,
    },
    relatedProducts,
    relatedArticles,
  },
}) => {
  const { isMobile, isTablet } = useScreenRecognition();
  let bannerImage = banner.imageLg;

  if (isMobile && banner.imageSm) {
    bannerImage = banner.imageSm;
  } else if (isTablet && banner.imageMd) {
    bannerImage = banner.imageMd;
  }

  const {
    commonSettings: { showRelatedProduct },
  } = defaultCompositions;

  const isRelatedProductVisible = !!Number(showRelatedProduct);

  const [showModal, setShowModal] = useState(false);
  const [videoURL, setVideoURL] = useState('');
  const [title, setTitle] = useState('');

  const openModal = (e, video, titleModal) => {
    e.preventDefault();
    e.stopPropagation();
    setTitle(titleModal);
    setVideoURL(video);
    setShowModal(true);
  };

  const close = () => {
    setShowModal(false);
    setVideoURL('');
  };

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      headerTransparent
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoExternalHreflangs,
      }}
    >
      <Banner
        inFirstViewPort
        title={banner.title}
        imageAlt={banner.imageAlt}
        image={bannerImage}
        url={url}
        className="influencers__banner"
        isExtendedBanner={
          banner?.isExtendedBanner ? Boolean(Number(banner?.isExtendedBanner)) : false
        }
      />
      <InfluencerSlider {...{ influencers, openModal }} />

      {isRelatedProductVisible ? (
        <div className="related-products__wrapper influencers__products">
          <RelatedProducts
            mainProduct={{
              nodes: [],
            }}
            {...{
              relatedProducts,
              relatedProductsCardCtaButton,
              relatedProductsCtaButton,
              relatedProductsTitle,
              relatedProductsCardCTAButtonAriaLabel,
              relatedProductsCtaButtonShow,
            }}
          />
        </div>
      ) : null}
      <div className="influencers__articles">
        <RelatedArticles
          breakpoints={breakpoints}
          data={relatedArticles.nodes}
          title={relatedArticlesTitle}
          readMoreText={relatedArticlesCTAButtonText}
          ariaLabel={relatedArticlesCTAButtonAriaLabel}
          articlesCount={isMobile ? 1 : 3}
        />
      </div>
      <Modal {...{ title, videoURL, close, showModal }} />
      <DangerouslySetInnerHtml html={extraFooter} className="influencers__footer" />
    </Layout>
  );
};

export const query = graphql`
  query($url: String, $lang: String, $links: [String], $relatedArticlesLinks: [String]) {
    umbracoInfluencer(url: { eq: $url }, lang: { eq: $lang }) {
      url
      seoMetaDescription
      seoMetaKeywords
      seoMetaTitle
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      banner {
        properties {
          imageAlt
          title
          imageLg: localImage {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageSm: localImage {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageMd: localImage {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          isExtendedBanner
        }
      }
      influencers {
        properties {
          title
          subTitle
          icon {
            url
            altText
            fallbackUrl
          }
          cta {
            properties {
              name
              text
              ariaLabel
              url {
                name
                url
              }
            }
          }
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      extraFooter
      relatedProductsCardCtaButton
      relatedProductsCtaButtonShow
      relatedProductsTitle
      relatedProductsCardCTAButtonAriaLabel
      relatedProductsCtaButton {
        url
        name
      }
      relatedArticlesTitle
      relatedArticlesCTAButtonAriaLabel
      relatedArticlesCTAButtonText
    }
    relatedProducts: allUmbracoProducts(filter: { url: { in: $links } }) {
      nodes {
        url
        properties {
          image {
            properties {
              imageAltLabel
            }
            localImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
        }
      }
    }
    relatedArticles: allUmbracoArticles(filter: { url: { in: $relatedArticlesLinks } }) {
      nodes {
        id
        title
        properties {
          title
          articleBody {
            structure
            properties {
              articleIntroductionText
            }
          }
          articleHeroBannerMainImageAltText
        }
        url
        localImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default InfluencerPage;

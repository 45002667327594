import React, { FC } from 'react';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';
import { IModalComponentProps } from './models';
import './Modal.scss';

const Modal: FC<IModalComponentProps> = ({ title = '', videoURL = '', close, showModal }) => {
  const videoArray = videoURL.split('/');
  let videoCode = videoArray[videoArray.length - 1];

  if (videoCode.includes('?')) {
    const paramsArray = videoCode.split('?')[1].split('&');
    const paramsVals: any = {};
    paramsArray.forEach((param) => {
      const [index, value] = param.split('=');
      paramsVals[index] = value;
    });
    videoCode = paramsVals.v;
  }

  return (
    <div className={showModal ? 'modal__shadow' : 'modal__shadow modal--hidden'}>
      <div className="modal__container">
        <button onClick={close} className="modal__close" type="button">
          &times;
        </button>
        <DangerouslySetInnerHtml html={title} className="modal__header" />
        <div className="modal__body">
          <iframe
            src={`https://www.youtube.com/embed/${videoCode}`}
            className="modal__body__content"
            title="Video Player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
};

export default Modal;
